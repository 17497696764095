import React, { useState } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import UpdateOfferStatusModal from "../Modals/UpdateOfferStatus";
import CardWithIcon from "../Elements/CardWithIcon";
import { AddCircle, Send, Update } from "@mui/icons-material";
import SendConfirmationModal from "../Modals/SendConfirmationModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import {
	deleteOffers,
	sendLoanOffer,
} from "../../../common/services/retool-service";

const OffersTable = ({
	offersSearchTerm,
	handleOffersSearchChange,
	isLoadingOffers,
	filteredOffersData,
	setSelectedRow,
	selectedRow,
	setOpenCreateOfferModal,
	handleDelete,
	setSelectedRows,
	selectedRows,
	totalItems,
	page,
	handleFetchNewPage,
	isRefetching,
	handleSize,
	handleSearch,
}) => {
	const theme = useTheme();
	const [openUpdateOfferStatusModal, setOpenUpdateOfferStatusModal] =
		useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();
	const [isApproved, setIsApproved] = useState(false);

	const [openSendOfferStatusModal, setOpenSendOfferStatusModal] =
		useState(false);
	const [openSendOfferStatusModal2, setOpenSendOfferStatusModal2] =
		useState(false);

	const handleMultipleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedRows.map((row) => row.email).join(", ");

		setConfirmationMessage(
			`Are you sure you want to delete these offers: ${emails}?`
		);
	};

	const handleSendOfferConfirmation = () => {
		const data = selectedRow;
		if (data?.loanStatus == "Approved") {
			setOpenSendOfferStatusModal(true);

			setConfirmationMessage(
				`This action will send the loan offer to this email: ${data?.email}. Are you sure you want to continue?`
			);
		} else {
			setOpenSendOfferStatusModal2(true);

			setConfirmationMessage(
				`You can not proceed with this offer: ${data?.email} until the deal is approved.`
			);
		}
	};

	const handleDeleteOffers = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: selectedRows.map((row) => row.id),
			};

			const response = await deleteOffers(payload);
			if (response.success) {
				toast.success(response.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
				queryClient.invalidateQueries("approvedOffers");
				queryClient.invalidateQueries("loanOffersSummary");
			} else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
		} catch (error) {
			toast.error("Unable to delete offers.");
			setIsLoading(false);
		}
	};

	const handleSendOffer = async () => {
		try {
			setIsLoading(true);
			const data = selectedRow;
			const response = await sendLoanOffer(data.id, isApproved);
			if (response?.data?.success) {
				toast.success(response?.data?.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
				queryClient.invalidateQueries("approvedOffers");
				queryClient.invalidateQueries("loanOffersSummary");
			} else toast.error(response?.data?.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			setOpenSendOfferStatusModal(false);
			setOpenSendOfferStatusModal2(false);
		} catch (error) {
			toast.error("Unable to send loan offer");

			setIsLoading(false);
		}
	};

	return (
		<>
			<Box display="flex" alignItems="center" gap={2} my={2}>
				<Box sx={{ flexGrow: 1 }}>
					<CustomSearchBar
						searchTerm={offersSearchTerm}
						onSearchChange={handleOffersSearchChange}
						handleSearch={handleSearch}
					/>
				</Box>

				<Box sx={{ marginLeft: "auto" }}>
					{selectedRows.length >= 1 && (
						<Box sx={{ marginLeft: "auto" }} display="flex" gap={2}>
							{selectedRows.length === 1 && (
								<>
									<CardWithIcon
										icon={
											<Edit
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Edit"
										onClick={() => {
											setOpenCreateOfferModal(true);
										}}
									/>
									<CardWithIcon
										icon={
											<Send
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Send"
										onClick={() => {
											handleSendOfferConfirmation();
										}}
									/>
									<CardWithIcon
										icon={
											<Update
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Update Status"
										onClick={() => {
											setOpenUpdateOfferStatusModal(true);
										}}
									/>
								</>
							)}
							<CardWithIcon
								icon={
									<Delete
										style={{
											color:
												theme.palette.mode == "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "18px",
										}}
									/>
								}
								text="Delete"
								onClick={() => {
									handleMultipleDelete();
								}}
							/>
						</Box>
					)}
				</Box>

				<UpdateOfferStatusModal
					open={openUpdateOfferStatusModal}
					onClose={() => setOpenUpdateOfferStatusModal(false)}
					offer={selectedRow}
				/>
			</Box>
			<CustomTable
				isLoading={isLoadingOffers || isRefetching}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="View/Update Loan Offer" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(offer);
							setOpenCreateOfferModal(true);
						},
					},
					{
						icon: (
							<Tooltip title="Delete Loan Offer" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode === "light" ? "red" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => handleDelete(offer),
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Amount", key: "approvedFundingAmount", sortable: true },
					{ title: "Duration (days)", key: "approvedDuration", sortable: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredOffersData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => {
					setSelectedRows(rows);
					setSelectedRow(rows[rows ? rows.length - 1 : 0]);
				}}
				selected={selectedRows}
				totalItems={totalItems || 0}
				handleNextPage={handleFetchNewPage}
				page={page}
				handleSize={handleSize}
			/>

			<ConfirmationModal
				open={openSendOfferStatusModal}
				onClose={() => setOpenSendOfferStatusModal(false)}
				handleConfirmAction={handleSendOffer}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteOffers}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<SendConfirmationModal
				open={openSendOfferStatusModal2}
				onClose={() => setOpenSendOfferStatusModal2(false)}
				onConfirm={handleSendOffer}
				message={confirmationMessage}
				isLoading={isLoading}
				setIsApproved={setIsApproved}
				isApproved={isApproved}
			/>
		</>
	);
};

export default OffersTable;
