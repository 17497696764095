import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	useTheme,
	Tooltip,
	Divider,
	Button,
} from "@mui/material";
import { useQuery } from "react-query";
import CustomTable from "../Tables/CustomTable";
import CustomTextInput from "../Elements/CustomTextInput";
import CopyIcon from "@mui/icons-material/FileCopy";
import { toast } from "react-hot-toast";
import {
	getCommercialCreditReport,
	getConsumerCreditReport,
} from "../../services/retool-service";
import { ContentCopyOutlined } from "@mui/icons-material";
import StatusChip from "../Elements/StatusChip";
import { useNavigate } from "react-router-dom";
import LoadingIconButton from "../Elements/LoadingIconButton";
import GreetingMessage from "../Headings/GreetingMessage";
import useUserBankData from "../../../common/helpers/hooks/useUserBankData";
import moment from "moment";
import FinancialDataModal from "../Modals/FinancialDataModal";
import { useFetchFundingLadder } from "../../../common/helpers/hooks/useDealService";

interface CreditReportDetailsProps {
	selectedApplication: any;
}

const DataExtractionDetails: React.FC<CreditReportDetailsProps> = ({
	selectedApplication,
}) => {
	const userId = selectedApplication;
	const theme = useTheme();
	const navigate = useNavigate();
	const [openFinancialAnalysisModal, setOpenFinancialAnalysisModal] =
		useState(false);
	const {
		uploadedBankStatementsData,
		transactionHistoriesData,
		isLoadingHistories,
		isLoadingStatements,
		refetchHistories,
		refetchStatements,
	} = useUserBankData(userId);
	transactionHistoriesData.forEach((x: any) => {
		if (x?.date) {
			x.date = moment(x.date, "YYYYMMDDHHmmss").isValid()
				? moment(x.date, "YYYYMMDDHHmmss").format("YYYY-MM-DD")
				: null;
		}
	});

	const validHistories = transactionHistoriesData.filter((x: any) => x?.date);
	const {
		data: consumerData,
		isLoading: consumerLoading,
		isRefetching: consumerRefetching,
		refetch: refetchConsumerReport,
	} = useQuery(
		["consumerCreditReports", userId],
		() => (userId ? getConsumerCreditReport(userId) : Promise.resolve(null)),
		{ staleTime: Infinity, enabled: !!userId }
	);
	const arrayToCsvDownload = (array: any[], filename: string) => {
		const csvHeader = [
			"Date",
			"Transaction Description",
			"Amount",
			"Balance",
			"MA - Level 2",
			"MA - Level 1",
			"Red Flags Transaction?",
			"For Client Recon?",
		].join(",");

		const csvKeys = [
			"date",
			"memo",
			"amount",
			"balance",
			"secondaryCategory",
			"primaryCategory",
			"flagType",
			"clientRecon",
		];

		const csvRows = array.map((row) =>
			csvKeys
				.map((key) => {
					if (key === "memo") {
						return `"${row[key] ?? row.descriptionLines?.[0] ?? ""}"`;
					}
					if (key === "clientRecon") {
						return `"${row[key] ? "Yes" : "No"}"`;
					}
					if (key === "flagType") {
						return `"${row[key] ?? ""}"`;
					}
					return `"${row[key] ?? ""}"`;
				})
				.join(",")
		);

		const csvString = `${csvHeader}\n${csvRows.join("\n")}`;
		const blob = new Blob([csvString], { type: "text/csv" });
		const link = document.createElement("a");
		const url = window.URL.createObjectURL(blob);

		link.href = url;
		link.download = `${filename}.csv`;

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const {
		data: commercialData,
		isLoading: commercialLoading,
		isRefetching: commercialRefetching,
		refetch: refetchCommercialReport,
	} = useQuery(
		["commercialCreditReports", userId],
		() => (userId ? getCommercialCreditReport(userId) : Promise.resolve(null)),
		{ staleTime: Infinity, enabled: !!userId }
	);

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			toast.success("Copied to clipboard!");
		});
	};
	const entrepreneurFields = [
		{ label: "First Name", value: selectedApplication?.userProfile?.firstName },
		{ label: "Surname", value: selectedApplication?.userProfile?.surname },
		{ label: "Email", value: selectedApplication?.userProfile?.email },
		{ label: "Mobile No", value: selectedApplication?.userProfile?.mobileNo },
		{ label: "Gender", value: selectedApplication?.userProfile?.gender },
		{ label: "ID Number", value: selectedApplication?.userProfile?.idNumber },
		{ label: "Age", value: selectedApplication?.userProfile?.age },
		{
			label: "Date of Birth",
			value: selectedApplication?.userProfile?.dateOfBirth,
		},
		{
			label: "Residential Address",
			value: selectedApplication?.userProfile?.residentialAddress,
		},
	];

	const businessFields = [
		{
			label: "Business Name",
			value: selectedApplication?.businessProfile?.businessName,
		},
		{
			label: "Company Reg No",
			value: selectedApplication?.businessProfile?.companyRegNo,
		},
		{
			label: "Financial Year End",
			value: selectedApplication?.businessProfile?.financialYearEnd,
		},
		{
			label: "Commercial Status",
			value: selectedApplication?.businessProfile?.commercialStatus,
		},
		{
			label: "Physical Address",
			value: selectedApplication?.businessProfile?.physicalAddress,
		},
		{
			label: "Director Count",
			value: selectedApplication?.businessProfile?.directorCount,
		},
		{
			label: "Legal Entity Type",
			value: selectedApplication?.businessProfile?.legalEntityType,
		},
		{ label: "Tax No", value: selectedApplication?.businessProfile?.taxNo },
	];

	const directorColumns = [
		{ title: "Full Name", key: "fullName", sortable: true },
		{ title: "Phone Number", key: "phoneNumber", sortable: true },
		{ title: "ID Number", key: "idNumber", sortable: true },
		{ title: "Birth Date", key: "birthDate", sortable: true },
		{ title: "Director Status", key: "directorStatus", sortable: true },
		{ title: "Appointment Date", key: "appointmentDate", sortable: true },
	];

	const consumerCreditScore =
		consumerData?.data?.data?.consumerScoring?.finalScore || "-";
	const consumerCreditCategory =
		consumerData?.data?.data?.consumerScoring?.riskCategory || "N/A";
	const commercialCreditScore =
		commercialData?.data?.data?.commercialScoring?.finalScore || "-";
	const commercialCreditCategory =
		commercialData?.data?.data?.commercialScoring?.band || "N/A";
	const commercialCreditReport =
		commercialData?.data?.data?.commercialBusinessInformation || {};
	const applyingDirectorCreditReport =
		consumerData?.data?.data?.consumerDetail || {};
	const employmentHistory =
		consumerData?.data?.data?.consumerEmploymentHistory || [];
	const properties =
		consumerData?.data?.data?.consumerPropertyInformationSummary || [];
	const consumerAccounts =
		consumerData?.data?.data?.consumerNLRAccountStatus || [];

	const directorData =
		commercialData?.data?.data?.commercialPrincipalInformation || [];

	const { data: fundingLadder, isLoading: isFetchingFundingLadder } =
		useFetchFundingLadder(selectedApplication?.userId);

	return (
		<Box
			sx={{
				width: "100%",
				height: "100vh",
				backgroundColor:
					theme.palette.mode === "dark"
						? theme.palette.background.default
						: theme.palette.background.paper,

				borderRadius: "12px",
				margin: "auto",
			}}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				my={2}
			>
				<GreetingMessage title="Deal Details" />
				<LoadingIconButton
					onClick={() => {
						refetchCommercialReport();
						refetchConsumerReport();
						refetchHistories();
						refetchStatements();
					}}
					isLoading={
						consumerRefetching ||
						commercialRefetching ||
						isLoadingHistories ||
						isLoadingStatements
					}
				/>
			</Box>

			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12} sm={4} sx={{ cursor: "pointer" }}>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Funding Amount</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(selectedApplication?.fundingAmount))
									? 0
									: Number(selectedApplication?.fundingAmount)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(selectedApplication?.fundingAmount);
										}}
										sx={{ ml: 1 }}
										disabled={!selectedApplication?.fundingAmount}
									>
										<ContentCopyOutlined
											color={
												selectedApplication?.fundingAmount
													? "success"
													: "disabled"
											}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4} sx={{ cursor: "pointer" }}>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Duration</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(selectedApplication?.duration))
									? 0
									: Number(selectedApplication?.duration)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(selectedApplication?.duration);
										}}
										sx={{ ml: 1 }}
										disabled={!selectedApplication?.duration}
									>
										<ContentCopyOutlined
											color={
												selectedApplication?.duration ? "success" : "disabled"
											}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4} sx={{ cursor: "pointer" }}>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Use of funds</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{selectedApplication?.useOfFunds}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(selectedApplication?.useOfFunds);
										}}
										sx={{ ml: 1 }}
										disabled={!selectedApplication?.useOfFunds}
									>
										<ContentCopyOutlined
											color={
												selectedApplication?.useOfFunds ? "success" : "disabled"
											}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					sx={{ cursor: "pointer" }}
					onClick={() => navigate("/manage-reports")}
				>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Commercial Credit Score</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(commercialCreditScore))
									? 0
									: Number(commercialCreditScore)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(commercialCreditScore);
										}}
										sx={{ ml: 1 }}
										disabled={!commercialCreditScore}
									>
										<ContentCopyOutlined
											color={commercialCreditScore ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
							{commercialCreditCategory && (
								<StatusChip value={commercialCreditCategory} />
							)}
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					sx={{ cursor: "pointer" }}
					onClick={() => navigate("/manage-reports")}
				>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Consumer Credit Score</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(consumerCreditScore))
									? 0
									: Number(consumerCreditScore)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(consumerCreditScore);
										}}
										sx={{ ml: 1 }}
										disabled={!consumerCreditScore}
									>
										<ContentCopyOutlined
											color={consumerCreditScore ? "success" : "disabled"}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
							{consumerCreditCategory && (
								<StatusChip value={consumerCreditCategory} />
							)}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4} sx={{ cursor: "pointer" }}>
					<Box
						sx={{
							border: "1px solid",
							padding: "16px",
							borderRadius: "8px",
							textAlign: "center",
						}}
					>
						<Typography variant="h6">Transactions</Typography>
						<Box sx={{ mt: 1 }}>
							<Typography variant="h6">
								{isNaN(Number(transactionHistoriesData?.length))
									? 0
									: Number(transactionHistoriesData?.length)}
								<Tooltip title="Copy to Clipboard">
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleCopyToClipboard(transactionHistoriesData?.length);
										}}
										sx={{ ml: 1 }}
										disabled={!transactionHistoriesData?.length}
									>
										<ContentCopyOutlined
											color={
												transactionHistoriesData?.length
													? "success"
													: "disabled"
											}
										/>
									</IconButton>
								</Tooltip>
							</Typography>
							<Box
								p={0.7}
								sx={{
									display: "flex",
									gap: 2,
									justifyContent: "center",
									alignItems: "center",
									visibility:
										transactionHistoriesData?.length > 0 ? "visible" : "hidden",
								}}
							>
								<Button
									sx={{
										width: "93px",
										height: "19px",
										padding: "10px 24px",
										gap: "8px",
										borderRadius: "4px",
										backgroundColor: "white",
										border: "1px solid rgba(61, 127, 142, 1)",
										color: "rgba(61, 127, 142, 1)",
										marginRight: "8px",
									}}
									onClick={() =>
										arrayToCsvDownload(validHistories, "extracted_transactions")
									}
									variant="outlined"
								>
									Export
								</Button>

								<Button
									variant="contained"
									color="success"
									onClick={() => setOpenFinancialAnalysisModal(true)}
									sx={{
										width: "93px",
										height: "19px",
										padding: "10px 24px",
										gap: "8px",
										borderRadius: "4px",
										backgroundColor: "rgba(61, 127, 142, 1)",
										color: "white",
									}}
								>
									View
								</Button>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Divider sx={{ mt: 4 }} />
			<Box p={2}>
				<Typography variant="h6">Entrepreneur Details</Typography>
				<form>
					<Grid container spacing={2}>
						{entrepreneurFields.map((field, index) => (
							<Grid item xs={12} md={4} key={index}>
								<Box display="flex" alignItems="center">
									<CustomTextInput label={field.label} value={field.value} />
									<Tooltip title="Copy to Clipboard">
										<IconButton
											onClick={() =>
												field.value && handleCopyToClipboard(field.value)
											}
											sx={{ ml: 1 }}
											disabled={!field.value}
										>
											<ContentCopyOutlined
												color={field.value ? "success" : "disabled"}
											/>
										</IconButton>
									</Tooltip>
								</Box>
							</Grid>
						))}
					</Grid>
				</form>

				<Divider sx={{ mt: 4 }} />

				<Box mt={2}>
					<Typography variant="h6">Company Details</Typography>
					<form>
						<Grid container spacing={2}>
							{businessFields.map((field, index) => (
								<Grid item xs={12} md={4} key={index}>
									<Box display="flex" alignItems="center">
										<CustomTextInput label={field.label} value={field.value} />
										<Tooltip title="Copy to Clipboard">
											<IconButton
												onClick={() =>
													field.value && handleCopyToClipboard(field.value)
												}
												sx={{ ml: 1 }}
												disabled={!field.value}
											>
												<ContentCopyOutlined
													color={field.value ? "success" : "disabled"}
												/>
											</IconButton>
										</Tooltip>
									</Box>
								</Grid>
							))}
						</Grid>
					</form>
				</Box>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Funding Ladder History</Typography>
				<CustomTable
					isLoading={isFetchingFundingLadder}
					columns={[
						{
							title: "Product",
							key: "productType",
							sortable: true,
							isPill: true,
						},
						{ title: "Loan Amount", key: "fundingAmount", sortable: true },

						{
							title: "Loan Status",
							key: "status",
							sortable: true,
							isPill: true,
						},
					]}
					data={fundingLadder?.data?.data || []}
					copyToClipboard={true}
				/>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Director Details</Typography>
				<CustomTable
					isLoading={commercialLoading}
					columns={[
						{ title: "Full Name", key: "fullname" },
						{ title: "Phone Number", key: "cellularNo" },
						{ title: "ID Number", key: "idNo" },
						{ title: "Birth Date", key: "birthDate" },
						{ title: "Director Status", key: "directorStatusCode" },
						{ title: "Appointment Date", key: "appointmentDate" },
					]}
					data={directorData}
					copyToClipboard={true}
				/>
			</Box>
			<Divider sx={{ mt: 4 }} />
			<Box mt={4}>
				<Typography variant="h6">Consumer Directorship</Typography>
				<CustomTable
					copyToClipboard={true}
					isLoading={consumerLoading}
					columns={[
						{ title: "Name", key: "commercialName" },
						{ title: "Status", key: "commercialStatus", isPill: true },
						{ title: "Registration No", key: "registrationNo" },
						{ title: "Director Status", key: "directorStatus" },
						{ title: "Appointment Date", key: "appointmentDate" },
					]}
					data={employmentHistory}
				/>
			</Box>

			<FinancialDataModal
				open={openFinancialAnalysisModal}
				onClose={() => setOpenFinancialAnalysisModal(false)}
				uploadedStatements={uploadedBankStatementsData || []}
				transactionHistories={transactionHistoriesData || []}
				type="OCR"
				isFetchingTransactionHistories={isLoadingHistories}
				isFetchingUploadedStatements={isLoadingStatements}
			/>
		</Box>
	);
};

export default DataExtractionDetails;
