import React from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
	Avatar,
	Paper,
	Tooltip,
	Slider,
	useMediaQuery,
	Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedRow: any;
}

const CV4BReportDetailsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedRow
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (!selectedRow) {
		return null;
	}

	const userInfo = {
		name: selectedRow.userProfile?.firstName || 'N/A',
		surname: selectedRow.userProfile?.surname || 'N/A',
		dob: selectedRow.userProfile?.dateOfBirth || 'N/A',
		age: selectedRow.userProfile?.age || 'N/A',
		image: selectedRow.userProfile?.avatarImage || "https://example.com/default-avatar.jpg",
	};

	const businessInfo = {
		name: selectedRow.businessProfile?.businessName || 'N/A',
		regNo: selectedRow.businessProfile?.companyRegNo || 'N/A',
		address: selectedRow.businessProfile?.physicalAddress || 'N/A',
		status: selectedRow.businessProfile?.commercialStatus || 'N/A',
	};

	const financialScores = selectedRow.cV4BReport ? {
		"CV4B Band": selectedRow.cV4BReport.cV4BBAND,
		"Credit Verification Score": selectedRow.cV4BReport.cV4BSCORE,
		"Propensity Score": selectedRow.cV4BReport.prpscore,
		"Liquidity Score": selectedRow.cV4BReport.liqscore,
		"Judgmental Score": selectedRow.cV4BReport.jdgscore,
		"Delinquency Score": selectedRow.cV4BReport.dlqscore,
	} : null;

	const scoreNotes = {
		"Liquidity Score": "Measures financial liquidity or ability to meet short-term obligations. Higher scores indicate better liquidity.",
		"Judgmental Score": "Evaluates risk based on past judgments, defaults, or adverse data. Lower scores suggest higher risk due to defaults or derogatory status.",
		"Delinquency Score": "Predicts the likelihood of future delinquencies. Higher scores indicate a lower risk of delinquency.",
		"Propensity Score": "Represents the likelihood of a positive credit behavior, such as repayment or creditworthiness.",
		"Credit Verification Score": "General score to indicate overall credit health.",
	};

	const cv4bBandLegend = [
		{ band: "C1", risk: "Very Low Risk", color: theme.palette.success.main },
		{ band: "C2", risk: "Low Risk", color: theme.palette.success.light },
		{ band: "C3", risk: "Below Average Risk", color: theme.palette.info.main },
		{ band: "C4", risk: "Average Risk", color: theme.palette.warning.light },
		{ band: "C5", risk: "Above Average Risk", color: theme.palette.warning.main },
		{ band: "C6", risk: "High Risk", color: theme.palette.error.light },
		{ band: "C7", risk: "Very High Risk", color: theme.palette.error.main },
	];

	const scoreCalculations = selectedRow.cV4BReport ? {
		"Liquidity Score": [
			selectedRow.cV4BReport.liqaarC1,
			selectedRow.cV4BReport.liqaarC2,
			selectedRow.cV4BReport.liqaarC3,
			selectedRow.cV4BReport.liqaarC4,
		].filter(Boolean),
		"Judgmental Score": [
			selectedRow.cV4BReport.jdgaarC1,
			selectedRow.cV4BReport.jdgaarC2,
			selectedRow.cV4BReport.jdgaarC3,
			selectedRow.cV4BReport.jdgaarC4,
		].filter(Boolean),
		"Delinquency Score": [
			selectedRow.cV4BReport.dlqaarC1,
			selectedRow.cV4BReport.dlqaarC2,
			selectedRow.cV4BReport.dlqaarC3,
			selectedRow.cV4BReport.dlqaarC4,
		].filter(Boolean),
		"Propensity Score": [
			selectedRow.cV4BReport.prpaarC2,
			selectedRow.cV4BReport.prpaarC3,
		].filter(Boolean),
	} : null;

	const getScoreColor = (score: number) => {
		if (score < 600) return theme.palette.error.main;
		if (score < 800) return theme.palette.warning.main;
		return theme.palette.success.main;
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: isMobile ? '95%' : '90%',
					maxWidth: 1200,
					maxHeight: '90vh',
					bgcolor: theme.palette.background.paper,
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					borderRadius: 2,
				}}
			>
				<Box
					mb={3}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h4" component="h2">Background Checks</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<Paper elevation={3} sx={{ p: 3, mb: 3 }}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12} md={6}>
							<Typography variant="h6" gutterBottom>User Profile</Typography>
							<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
								<Avatar
									src={userInfo.image}
									sx={{ width: 100, height: 100, mr: 2 }}
								/>
								<Box>
									<Typography><strong>Name:</strong> {`${userInfo.name} ${userInfo.surname}`}</Typography>
									<Typography><strong>Date of Birth:</strong> {userInfo.dob}</Typography>
									<Typography><strong>Age:</strong> {userInfo.age}</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="h6" gutterBottom>Business Profile</Typography>
							<Typography><strong>Business Name:</strong> {businessInfo.name}</Typography>
							<Typography><strong>Registration No:</strong> {businessInfo.regNo}</Typography>
							<Typography><strong>Status:</strong> {businessInfo.status}</Typography>
							<Typography><strong>Address:</strong> {businessInfo.address}</Typography>
						</Grid>
					</Grid>
				</Paper>

				<Paper elevation={3} sx={{ p: 3, mb: 3 }}>
					<Typography variant="h5" gutterBottom>Overview</Typography>
					{selectedRow.amlCheckResponse ? (
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Typography><strong>Entity Type:</strong> {selectedRow.amlCheckResponse.entityType}</Typography>
								<Typography><strong>Entity Name:</strong> {selectedRow.amlCheckResponse.entityName}</Typography>
								<Typography><strong>Date Listed:</strong> {selectedRow.amlCheckResponse.dateListed}</Typography>
								<Typography><strong>Reasons Listed:</strong> {selectedRow.amlCheckResponse.reasonsListed?.join(", ") || 'N/A'}</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography><strong>Source:</strong> {selectedRow.amlCheckResponse.source}</Typography>
								<Typography><strong>Category:</strong> {selectedRow.amlCheckResponse.category}</Typography>
								<Typography><strong>Subcategory:</strong> {selectedRow.amlCheckResponse.subcategory}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" sx={{ mt: 2 }}>
									<strong>Profile Notes:</strong> {selectedRow.amlCheckResponse.profileNotes}
								</Typography>
							</Grid>
						</Grid>
					) : (
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
							<ReportProblemOutlinedIcon sx={{ fontSize: 60, color: theme.palette.text.secondary, mb: 2 }} />
							<Typography variant="body1" color="text.secondary">
								No results yielded from AML check
							</Typography>
						</Box>
					)}
				</Paper>

				<Paper elevation={3} sx={{ p: 3, mb: 3 }}>
					<Typography variant="h5" gutterBottom>Financial Health Status</Typography>
					{financialScores ? (
						<>
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
								{cv4bBandLegend.map((item) => (
									<Chip
										key={item.band}
										icon={<FiberManualRecordIcon style={{ color: item.color }} />}
										label={`${item.band} - ${item.risk}`}
										variant="outlined"
									/>
								))}
							</Box>
							<Grid container spacing={3}>
								{Object.entries(financialScores).map(([key, value]) => (
									<Grid item xs={12} sm={6} md={4} key={key}>
										<Tooltip title={scoreNotes[key as keyof typeof scoreNotes]} arrow>
											<Box>
												<Typography variant="subtitle1">{key}</Typography>
												<Slider
													value={typeof value === "number" ? value : 0}
													min={0}
													max={1000}
													valueLabelDisplay="auto"
													sx={{
														color: getScoreColor(
															typeof value === "number" ? value : 0
														),
													}}
												/>
												<Typography
													variant="h6"
													sx={{
														color: getScoreColor(
															typeof value === "number" ? value : 0
														),
														fontWeight: 'bold',
													}}
												>
													{value}
												</Typography>
											</Box>
										</Tooltip>
									</Grid>
								))}
							</Grid>
						</>
					) : (
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
							<ReportProblemOutlinedIcon sx={{ fontSize: 60, color: theme.palette.text.secondary, mb: 2 }} />
							<Typography variant="body1" color="text.secondary">
								No CV4B report data available
							</Typography>
						</Box>
					)}
				</Paper>

				{scoreCalculations && (
					<Paper elevation={3} sx={{ p: 3 }}>
						<Typography variant="h5" gutterBottom>
							Score Calculation Breakdown
						</Typography>
						<Grid container spacing={2}>
							{Object.entries(scoreCalculations).map(([scoreName, factors]) => (
								<Grid item xs={12} sm={6} key={scoreName}>
									<Typography variant="subtitle1" sx={{ color: financialScores ? getScoreColor(financialScores[scoreName as keyof typeof financialScores] as number) : theme.palette.text.primary }}>
										{scoreName}
									</Typography>
									<ul>
										{factors.map((factor, index) => (
											<li key={index}>{factor}</li>
										))}
									</ul>
								</Grid>
							))}
						</Grid>
					</Paper>
				)}
			</Box>
		</Modal>
	);
};

export default CV4BReportDetailsModal;