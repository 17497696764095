import React, { useState } from "react";
import { Modal, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { Close, Visibility, Delete, Download } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import PdfViewerModal from "./PdfViewModal";
import CustomTable from "../Tables/CustomTable";
import TabsWithSearch from "../Widgets/TabsWithSearch";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CardWithIcon from "../Elements/CardWithIcon";
import ConfirmationModal from "./ConfirmationModal";
import CustomTabs from "../Elements/CustomTabs";
import moment from "moment";
import { removeDocument } from "../../../common/services/retool-service";
import toast from "react-hot-toast";
import { useIsFetching, useQueryClient } from "react-query";
import LoadingIconButton from "../Elements/LoadingIconButton";

interface FinancialDataModalProps {
	open: boolean;
	onClose: () => void;
	transactionHistories: any[];
	uploadedStatements: any[];
	type: string;
	isFetchingUploadedStatements?: boolean;
}

const FinancialDataModal: React.FC<FinancialDataModalProps> = ({
	open,
	onClose,
	transactionHistories,
	uploadedStatements,
	type,
	isFetchingUploadedStatements,
}) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(1);
	const [searchQuery, setSearchQuery] = useState("");
	const [openConfirmSuccessModal, setOpenConfirmSuccessModal] = useState(false);
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");
	const plugin = defaultLayoutPlugin();
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const tabs = [
		// { label: "Uploaded Bank Statements", value: 0 },
		{ label: "Extracted Data", value: 1 },
	];

	const isFetching = useIsFetching();

	const refetch = () => {
		queryClient.invalidateQueries("uploadedBankStatements");
		queryClient.invalidateQueries("allUploadedBankStatements");
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const handleViewDocument = (row: any) => {
		if (row?.downloadUrl) {
			setPdfUrl(row.downloadUrl);
			setOpenPdfDialog(true);
		} else {
			toast.error("Unable to load the pdf. No url found.");
		}
	};

	const handleDeleteStatement = async (statement: any) => {
		setIsLoading(true);

		try {
			const response = await removeDocument({
				userId: statement?.userId,
				id: statement.id,
				documentId: statement.id,
				documentRef: statement.documentRef,
			});

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("uploadedBankStatements");
				queryClient.invalidateQueries("allUploadedBankStatements");
			} else toast.error(response.data.message);
		} catch (error) {
			toast.error("Failed to delete document.");
		} finally {
			setIsLoading(false);
			setOpenConfirmSuccessModal(false);
		}
	};

	const handleDeleteStatementModal = (row: any) => {
		setOpenConfirmSuccessModal(true);
		setSelectedRow(row);
	};

	const arrayToCsvDownload = (array: any[], filename: string) => {
		const csvHeader = [
			"Date",
			"Transaction Description",
			"Amount",
			"Balance",
			"MA - Level 2",
			"MA - Level 1",
			"Red Flags Transaction?",
			"For Client Recon?",
		].join(",");

		const csvKeys = [
			"date",
			"memo",
			"amount",
			"balance",
			"secondaryCategory",
			"primaryCategory",
			"flagType",
			"clientRecon",
		];

		const csvRows = array.map((row) =>
			csvKeys
				.map((key) => {
					if (key === "memo") {
						return `"${row[key] ?? row.descriptionLines?.[0] ?? ""}"`;
					}
					if (key === "clientRecon") {
						return `"${row[key] ? "Yes" : "No"}"`;
					}
					if (key === "flagType") {
						return `"${row[key] ?? ""}"`;
					}
					return `"${row[key] ?? ""}"`;
				})
				.join(",")
		);

		const csvString = `${csvHeader}\n${csvRows.join("\n")}`;
		const blob = new Blob([csvString], { type: "text/csv" });
		const link = document.createElement("a");
		const url = window.URL.createObjectURL(blob);

		link.href = url;
		link.download = `${filename}.csv`;

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const filteredUploadedBankStatements = uploadedStatements.filter(
		(each) =>
			each?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
			each?.status?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	transactionHistories.forEach((x: any) => {
		if (x?.date) {
			x.date = moment(x.date, "YYYYMMDDHHmmss").isValid()
				? moment(x.date, "YYYYMMDDHHmmss").format("YYYY-MM-DD")
				: null;
		}
	});

	const validHistories = transactionHistories.filter((x: any) => x?.date);

	const filteredTransactionHistories = validHistories.filter(
		(transaction: any) =>
			transaction?.memo.toLowerCase().includes(searchQuery.toLowerCase()) ||
			transaction?.primaryCategory
				.toLowerCase()
				.includes(searchQuery.toLowerCase()) ||
			transaction?.secondaryCategory
				.toLowerCase()
				.includes(searchQuery.toLowerCase())
	);
	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					backgroundColor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "24px", fontWeight: "500" }}
					>
						Financial Data
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				{type === "OCR" && (
					<>
						{tabIndex === 1 && (
							<CustomTabs
								tabs={tabs}
								selectedTab={tabIndex}
								onTabChange={(event, newValue) => setTabIndex(newValue)}
							/>
						)}
						{/* {tabIndex === 0 && (
							<>
								<TabsWithSearch
									tabs={tabs}
									selectedTab={tabIndex}
									onTabChange={(event, newValue) => setTabIndex(newValue)}
									searchTerm={searchQuery}
									onSearchChange={handleSearch}
								/>
								<Box
									textAlign="right"
									width="100%"
									display="flex"
									justifyContent="flex-end"
								>
									<LoadingIconButton
										onClick={refetch}
										isLoading={isFetching > 0}
									/>
								</Box>

								<CustomTable
									isLoading={isFetchingUploadedStatements}
									icons={(statement) => [
										{
											icon: (
												<Tooltip title="View Document" arrow>
													<Visibility
														sx={{
															fill:
																theme.palette.mode === "light"
																	? "rgba(73, 69, 79, 1)"
																	: "white",
															fontSize: "20px",
														}}
													/>
												</Tooltip>
											),
											onClick: () => handleViewDocument(statement),
										},
										{
											icon: (
												<Tooltip title="Delete" arrow>
													<Delete
														sx={{
															fill:
																theme.palette.mode === "light"
																	? "red"
																	: "white",
															fontSize: "20px",
														}}
													/>
												</Tooltip>
											),
											onClick: () => handleDeleteStatementModal(statement),
										},
									]}
									columns={[
										{ title: "Document", key: "name", sortable: true },
										{
											title: "Uploaded At",
											key: "dateCreated",
											sortable: true,
										},
										{ title: "Status", key: "status", sortable: true },
									]}
									data={filteredUploadedBankStatements}
									actionColumn={{ title: "Actions" }}
									selectable={false}
								/>
							</>
						)} */}
					</>
				)}

				{(type === "Bank" || tabIndex === 1) && (
					<>
						<Box
							width="100%"
							my={2}
							gap={2}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Box sx={{ flexGrow: 1 }}>
								<CustomSearchBar
									searchTerm={searchQuery}
									onSearchChange={handleSearch}
								/>
							</Box>

							<Box sx={{ marginLeft: "auto" }}>
								<CardWithIcon
									icon={
										<Download
											style={{
												color:
													theme.palette.mode === "light"
														? "rgba(73, 69, 79, 1)"
														: "white",
												marginRight: "5px",
												fontSize: "34px",
											}}
										/>
									}
									text="Download"
									onClick={() =>
										arrayToCsvDownload(selectedRows, "extracted_transactions")
									}
									disabled={selectedRows.length < 1}
								/>
							</Box>
							<LoadingIconButton onClick={refetch} isLoading={isFetching > 0} />
						</Box>
						<CustomTable
							isLoading={isFetchingUploadedStatements}
							columns={[
								{ title: "Date", key: "date", sortable: true },
								{ title: "Description", key: "memo", sortable: true },
								{
									title: "Primary Category",
									key: "primaryCategory",
									sortable: true,
								},
								{
									title: "Secondary Category",
									key: "secondaryCategory",
									sortable: true,
								},
								{
									title: "Amount",
									key: "amount",
									sortable: true,
									withSignFormating: true,
								},
								{
									title: "Balance",
									key: "balance",
									sortable: true,
									withSignFormating: true,
								},
							]}
							data={filteredTransactionHistories}
							selectable={true}
							onRowSelect={(selectedRows: any) => setSelectedRows(selectedRows)}
							selected={selectedRows}
							count={100}
						/>
					</>
				)}

				<PdfViewerModal
					open={openPdfDialog}
					handleClose={() => setOpenPdfDialog(false)}
					pdfUrl={pdfUrl}
					plugin={plugin}
				/>
				<ConfirmationModal
					open={openConfirmSuccessModal}
					onClose={() => setOpenConfirmSuccessModal(false)}
					message={`Are you sure you want to delete this document
						(${selectedRow?.documentRef})?`}
					handleConfirmAction={() => handleDeleteStatement(selectedRow)}
					isLoading={isLoading}
				/>
			</Box>
		</Modal>
	);
};

export default FinancialDataModal;
