import React, { useState } from "react";
import BankAccountModal from "./BankAccountModal";
import BankAccountForm from "../Forms/BankAccountForm";
import { createBankAccount } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";

const CreateBankAccount = ({ open, onClose, userId }) => {
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		accountName: "",
		accountNumber: "",
		bankName: "",
		userId,
	});

	const handleCreate = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Creating bank account...");

		try {
			const response = await createBankAccount(formData);
			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				queryClient.invalidateQueries("clientBankAccounts");
			} else toast.error(response.data.message, { id: toastId });
		} catch (error) {
			toast.error("Failed to create bank account.", { id: toastId });
		} finally {
			onClose();
			setFormData({
				accountName: "",
				accountNumber: "",
				bankName: "",
			});
			setIsLoading(false);
		}
	};

	return (
		<BankAccountForm
			open={open}
			onClose={onClose}
			title="Create Bank Account"
			formData={formData}
			setFormData={setFormData}
			onSave={handleCreate}
			isLoading={isLoading}
		/>
	);
};

export default CreateBankAccount;
