import { useQuery, useMutation } from "react-query";
import {
	getBankAccounts,
	getClientBankAccounts,
} from "../../services/retool-service";

export const useFetchBankAccounts = (params = {}) => {
	return useQuery(["bankAccounts", params], () => getBankAccounts(params), {
		keepPreviousData: true,
	});
};

export const useFetchClientBankAccounts = (userId: string) => {
	return useQuery(["clientBankAccounts", userId], () =>
		getClientBankAccounts(userId)
	);
};
