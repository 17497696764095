import React from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	useTheme,
	CircularProgress,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import CustomSelect from "../Elements/CustomSelect";

const BankAccountForm = ({
	open,
	onClose,
	title,
	formData,
	setFormData,
	onSave,
	isLoading,
}) => {
	const theme = useTheme();

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "400px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					{title}
				</Typography>

				<CustomTextInput
					name="accountName"
					label="Account Name"
					value={formData.accountName}
					onChange={(e) =>
						setFormData({ ...formData, accountName: e.target.value })
					}
					fullWidth
				/>
				<CustomTextInput
					name="accountNumber"
					label="Account Number"
					value={formData.accountNumber}
					onChange={(e) =>
						setFormData({ ...formData, accountNumber: e.target.value })
					}
					fullWidth
				/>

				<CustomSelect
					name="bankName"
					label="Select Bank"
					value={formData.bankName}
					onChange={(e) =>
						setFormData({ ...formData, bankName: e.target.value })
					}
					options={[
						{ value: "FNB", label: "FNB" },
						{ value: "StandardBank", label: "Standard Bank" },
						{ value: "Capitec", label: "Capitec" },
						{ value: "Absa", label: "Absa" },
						{ value: "Nedbank", label: "Nedbank" },
						{ value: "Investec", label: "Investec" },
						{ value: "Discovery", label: "Discovery" },
						{ value: "Unknown", label: "Unknown" },
					]}
					sx={{ mb: 2 }}
				/>

				<Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton text="Save" onClick={onSave} variant="contained" />
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default BankAccountForm;
