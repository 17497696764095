import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopy";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomTabs from "../Elements/CustomTabs";
import CustomTable from "../Tables/CustomTable";
import { useFetchClientDeals } from "../../helpers/hooks/useClientService";
import LoadingIconButton from "../Elements/LoadingIconButton";
import toast from "react-hot-toast";
import CreditReportDetails from "../Forms/CreditReportDetails";
import { ContentCopyOutlined } from "@mui/icons-material";
import DataExtractionDetails from "../Forms/DataExtractionDetails";

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
}

const DataExtractionDetailsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedApplication,
}) => {
	const theme = useTheme();

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">View Details</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ ml: "auto" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<DataExtractionDetails selectedApplication={selectedApplication} />
			</Box>
		</Modal>
	);
};

export default DataExtractionDetailsModal;
