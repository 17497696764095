import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import { Close, Download } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CustomTable from "../Tables/CustomTable";
import CustomSelect from "../Elements/CustomSelect";
import CustomSearchBar from "../Elements/CustomSearchBar";
import { useFetchClientTransactions } from "../../../common/helpers/hooks/useClientService";
import { useFetchClientBankAccounts } from "../../../common/helpers/hooks/useBankAccountService";
import { useQueryClient } from "react-query";
import LoadingIconButton from "../Elements/LoadingIconButton";
import moment from "moment";
import CardWithIcon from "../Elements/CardWithIcon";

interface FinancialDataModalProps {
	open: boolean;
	onClose: () => void;
	userId: string;
}

const LabelledFinancialDataModal: React.FC<FinancialDataModalProps> = ({
	open,
	onClose,
	userId,
}) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const [searchQuery, setSearchQuery] = useState("");
	const [bankAccountId, setBankAccountId] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } =
		useFetchClientBankAccounts(userId);

	const {
		data: transactions,
		isLoading,
		refetch,
		isRefetching,
	} = useFetchClientTransactions(userId, bankAccountId);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};
	const filteredTransactions = transactions?.data?.data?.filter(
		(transaction: any) =>
			transaction?.description
				.toLowerCase()
				.includes(searchQuery.toLowerCase()) ||
			transaction?.primaryCategory
				.toLowerCase()
				.includes(searchQuery.toLowerCase()) ||
			transaction?.secondaryCategory
				.toLowerCase()
				.includes(searchQuery.toLowerCase())
	);

	const arrayToCsvDownload = (array: any[], filename: string) => {
		const csvHeader = [
			"Date",
			"Description",
			"Amount",
			"Balance",
			"MA - Level 2",
			"MA - Level 1",
			"Red Flags Transaction?",
			"For Client Recon?",
		].join(",");

		const csvKeys = [
			"date",
			"description",
			"amount",
			"balance",
			"secondaryCategory",
			"primaryCategory",
		];

		const csvRows = array.map((row) =>
			csvKeys
				.map((key) => {
					if (key === "memo") {
						return `"${row[key] ?? row.descriptionLines?.[0] ?? ""}"`;
					}
					if (key === "clientRecon") {
						return `"${row[key] ? "Yes" : "No"}"`;
					}
					if (key === "flagType") {
						return `"${row[key] ?? ""}"`;
					}
					return `"${row[key] ?? ""}"`;
				})
				.join(",")
		);

		const csvString = `${csvHeader}\n${csvRows.join("\n")}`;
		const blob = new Blob([csvString], { type: "text/csv" });
		const link = document.createElement("a");
		const url = window.URL.createObjectURL(blob);

		link.href = url;
		link.download = `${filename}.csv`;

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const handleBankAccountChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setBankAccountId(event.target.value as number);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					backgroundColor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "18px", fontWeight: "500" }}
					>
						Labelled Financial Data
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<Box
					width="100%"
					my={2}
					gap={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box sx={{ flexGrow: 1 }}>
						<CustomSearchBar
							searchTerm={searchQuery}
							onSearchChange={handleSearch}
						/>
					</Box>
					<LoadingIconButton onClick={refetch} isLoading={isLoading} />
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb={2}
				>
					<Box width="300px">
						<CustomSelect
							otherLabel={false}
							name="bankName"
							label="Select Bank Account"
							value={bankAccountId || 0}
							onChange={(e: any) => handleBankAccountChange(e)}
							options={[
								{ label: "All", value: 0 },
								...(bankAccounts?.data?.data?.map((row: any) => ({
									label: `${row.bankName} ${row.accountNumber}`,
									value: row.id,
								})) || []),
							]}
							sx={{ mb: 2, width: "auto" }}
							error={
								isLoadingBankAccounts && bankAccounts?.data?.data?.length === 0
							}
							helperText={
								isLoadingBankAccounts
									? "Loading bank accounts..."
									: bankAccounts?.data?.data?.length === 0
										? "No bank accounts available"
										: ""
							}
						/>
					</Box>

					<CardWithIcon
						icon={
							<Download
								style={{ color: theme.palette.text.primary, fontSize: "34px" }}
							/>
						}
						text="Download"
						onClick={() =>
							arrayToCsvDownload(selectedRows, "extracted_transactions")
						}
						disabled={selectedRows.length < 1}
					/>
				</Box>

				<CustomTable
					isLoading={isLoading || isRefetching}
					columns={[
						{ title: "Date", key: "date", sortable: true },
						{ title: "Description", key: "description", sortable: true },
						{
							title: "Primary Category",
							key: "primaryCategory",
							sortable: true,
						},
						{
							title: "Secondary Category",
							key: "secondaryCategory",
							sortable: true,
						},
						{
							title: "Amount",
							key: "amount",
							sortable: true,
							withSignFormating: true,
						},
						{
							title: "Balance",
							key: "balance",
							sortable: true,
							withSignFormating: true,
						},
					]}
					data={filteredTransactions}
					selectable={true}
					onRowSelect={(selectedRows: any) => setSelectedRows(selectedRows)}
					selected={selectedRows}
					count={100}
				/>
			</Box>
		</Modal>
	);
};

export default LabelledFinancialDataModal;
