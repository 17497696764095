import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import {
	AddCircle,
	Cloud,
	CloudCircleOutlined,
	Delete,
	Flag,
	Link,
	LockClock,
	People,
	PushPin,
	RequestPage,
	Visibility,
} from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";
import { CircularProgress, Grid, Tooltip, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import IconWithText from "../../common/components/Elements/IconWithText";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/components/Grids/ResponsiveGrid";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import {
	useFetchClientAndDealSummary,
	useFetchDeals,
} from "../../common/helpers/hooks/useClientService";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import AddClientModal from "../../common/components/Modals/AddClientModal";
import ProfileDetailsModal from "../../common/components/Modals/ProfileDetailsModal";
import { softDeleteDeals } from "../../common/services/retool-service"; // <-- updated method for deals
import toast from "react-hot-toast";
import ConfirmationModal from "../../common/components/Modals/ConfirmationModal";
import ClientsFloatingActionMenu from "../../common/components/Menus/ClientsFloatingActionMenu";
import DealsFloatingActionMenu from "../../common/components/Menus/DealsFloatingActionMenu";
import ReviewDealModal from "../../common/components/Modals/ReviewDealModal";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import CustomTabs from "../../common/components/Elements/CustomTabs";
import BackgroundJobTable from "../../common/components/Tables/BackgroudJobTable";
import DealOverviewModal from "../../common/components/Modals/DealOverviewModal";
import UpdateDealStatusModal from "../../common/components/Modals/UpdateDealStatus";
import UpdateMultipleDealsStatusesModal from "../../common/components/Modals/UpdateMultipleDealsStatuses";
import DataExtractionDetailsModal from "../../common/components/Modals/DataExtractionDetailsModal";

const ManageDealsPage: React.FC = () => {
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(10);
	const [search, setSearch] = useState("");

	const [openAddClientModal, setOpenAddClientModal] = useState(false);
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
	const {
		data: deals,
		refetch: refetchDeals,
		isLoading: isFetching,
		isRefetching,
	} = useFetchDeals({ page, size, searchTerm: search });

	const handleFetchNewPage = async (newPage: number) => {
		setPage(newPage);
	};
	const handleSize = async (newSize: number) => {
		setSize(newSize);
	};
	const { data: summary, refetch: refetchSummary } =
		useFetchClientAndDealSummary();
	const tabs = [{ label: "Deals" }, { label: "Jobs" }];
	const [tabIndex, setTabIndex] = useState(0);

	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [openUpdateDealStatusModal, setOpenUpdateDealStatusModal] =
		useState(false);

	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const theme = useTheme();

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const refetch = () => {
		refetchDeals();
		refetchSummary();
	};

	const [filteredData, setFilteredData] = useState([]);
	const handleDelete = (deal: any) => {
		setOpenConfirmationModal(true);
		setSelectedRow(deal);

		setConfirmationMessage(
			`Are you sure you want to delete this deal: ${deal?.email}? Deleting this deal will also remove associated (deal, offers and contracts.)`
		);
	};

	const handleDeleteDeal = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: [selectedRow?.id],
			};
			const response = await softDeleteDeals(payload);
			if (response.success) toast.success(response.message);
			else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			refetch();
		} catch (error) {
			toast.error("Unable to delete deal.");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const dealsData = deals?.data?.data;

		if (dealsData) {
			setFilteredData(dealsData);
			setSelectedRows([]);
		}
	}, [deals]);

	const handleView = (row: any) => {
		setSelectedRow(row);
		setOpenViewDetailsModal(true);
	};

	const location = useLocation();

	const handleSubmitSearch = () => {
		setSearch(searchTerm);
	};

	useEffect(() => {
		refetch();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<GreetingMessage title="Manage Deals" />

			<ResponsiveGrid columnCount={4}>
				<CardWithIcon
					text="Deals In Progress"
					icon={
						<People
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfInProgressDeals}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Approved Deals"
					icon={
						<LockClock
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfApprovedDeals}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Accepted Deals"
					icon={
						<Link
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfAcceptedDeals}
					sx={{ cursor: "auto" }}
				/>
				<CardWithIcon
					text="Data Requests"
					icon={
						<CloudCircleOutlined
							style={{
								color: theme.palette.text.primary,
								marginRight: "5px",
								fontSize: "34px",
							}}
						/>
					}
					label={summary?.data.noOfRequests}
					sx={{ cursor: "auto" }}
				/>
			</ResponsiveGrid>

			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={4}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<GreetingMessage title="Deals" />
						<LoadingIconButton onClick={refetch} isLoading={isRefetching} />
					</Box>
					<Box
						width="100%"
						my={2}
						gap={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<CustomSearchBar
								searchTerm={searchTerm}
								onSearchChange={handleSearchChange}
								handleSearch={handleSubmitSearch}
							/>
						</Box>
						<Box sx={{ marginLeft: "auto" }}>
							{selectedRows.length > 0 && (
								<Box sx={{ marginLeft: "auto" }}>
									<CardWithIcon
										icon={
											<AddCircle
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Update Status"
										onClick={() => {
											setOpenUpdateDealStatusModal(true);
										}}
									/>
								</Box>
							)}
						</Box>
					</Box>

					<CustomTable
						isLoading={isFetching || isRefetching}
						icons={(deal) => [
							{
								icon: (
									<Tooltip title="View Details" arrow>
										<Visibility
											sx={{
												fill:
													theme.palette.mode == "light"
														? "rgba(73, 69, 79, 1)"
														: "white",
												fontSize: "20px",
											}}
										/>
									</Tooltip>
								),
								onClick: () => handleView(deal),
							},
							{
								icon: (
									<Tooltip title="Delete Deal" arrow>
										<Delete
											sx={{
												fill: theme.palette.mode == "light" ? "red" : "white",
												fontSize: "20px",
											}}
										/>
									</Tooltip>
								),
								onClick: () => handleDelete(deal),
							},
						]}
						columns={[
							{ title: "Email", key: "email", sortable: true },

							{ title: "Amount", key: "fundingAmount", sortable: true },
							{ title: "Duration", key: "duration", sortable: true },

							{
								title: "Status",
								key: "status",
								sortable: true,
								isPill: true,
							},
							{
								title: "Product",
								key: "loanType",
								sortable: true,
								isPill: true,
							},
							{ title: "Use of funds", key: "useOfFunds", sortable: true },
						]}
						data={filteredData}
						actionColumn={{
							title: "Actions",
						}}
						onRowSelect={(selectedRows) => {
							setSelectedRows(selectedRows);
						}}
						selectable
						selected={selectedRows}
						totalItems={deals?.data?.totalItems || 0}
						handleNextPage={handleFetchNewPage}
						handleSize={handleSize}
					/>
				</Box>

				{selectedRows.length > 0 && (
					<DealsFloatingActionMenu
						refetchData={() => refetch()}
						selectedRows={selectedRows}
						data={selectedRows[selectedRows ? selectedRows.length - 1 : 0]}
					/>
				)}
			</BaseCard>
			<UpdateMultipleDealsStatusesModal
				refetch={() => refetch()}
				open={openUpdateDealStatusModal}
				onClose={() => setOpenUpdateDealStatusModal(false)}
				deals={selectedRows}
			/>
			{/* <DealOverviewModal
				open={openViewDetailsModal}
				onClose={() => setOpenViewDetailsModal(false)}
				deal={selectedRow}
			/> */}
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteDeal}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<DataExtractionDetailsModal
				open={openViewDetailsModal}
				onClose={() => setOpenViewDetailsModal(false)}
				selectedApplication={selectedRow}
			/>
		</Box>
	);
};

export default ManageDealsPage;
