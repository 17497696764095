import React, { useState } from "react";
import { Box, Tooltip, useTheme, Fab } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import Upload from "@mui/icons-material/CloudUpload";
import { toast } from "react-hot-toast";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import LoanContractUploadContract from "../Modals/LoanContractUploadModal";
import ContractsFloatingActionMenu from "../Menus/ContractsFloatingActionMenu";
import CardWithIcon from "../Elements/CardWithIcon";
import { AddCircle, Delete, Send, Update } from "@mui/icons-material";
import SendConfirmationModal from "../Modals/SendConfirmationModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import CreateContractModal from "../Modals/CreateContractModal";
import { useQueryClient } from "react-query";
import {
	deleteContracts,
	sendLoanContract,
} from "../../../common/services/retool-service";

const ContractsTable = ({
	contractsSearchTerm,
	handleContractsSearchChange,
	isContractsLoading,
	filteredContractsData,
	setSelectedRow,
	setOpenUpdateContractModal,
	setPdfUrl,
	setOpenPdfDialog,
	refetch,
	selectedRow,
	selectedContracts,
	setSelectedContracts,
	page,
	totalItems,
	handleFetchNewPage,
	isRefetching,
	handleSize,
	handleSearch,
}) => {
	const theme = useTheme();

	const [openUploadDialog, setOpenUploadDialog] = useState(false);
	const [openSendContractStatusModal, setOpenSendContractStatusModal] =
		useState(false);
	const [openSendContractStatusModal2, setOpenSendContractStatusModal2] =
		useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [isApproved, setIsApproved] = useState(false);

	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [openAddContractModal, setOpenAddContractModal] = useState(false);
	const queryClient = useQueryClient();

	const handleSendContractConfirmation = () => {
		if (selectedRow?.loanStatus == "Approved") {
			setOpenSendContractStatusModal(true);
			setConfirmationMessage(
				`This action will send the contract to this email: ${selectedRow?.email}. Are you sure you want to continue?`
			);
		} else {
			setOpenSendContractStatusModal2(true);
			// Temporarily removed
			// setConfirmationMessage(
			// 	`You can not proceed with this contract: ${data?.email} until the deal is approved.`
			// );
			setConfirmationMessage(
				`This action will send the contract to this email: ${selectedRow?.email}. Are you sure you want to continue?`
			);
		}
	};

	const handleSendContract = async () => {
		try {
			setIsLoading(true);

			const response = await sendLoanContract(selectedRow.id, isApproved);
			if (response?.data?.success) toast.success(response?.data?.message);
			else toast.error(response?.data?.message);
			setIsLoading(false);
			setOpenSendContractStatusModal(false);
			setOpenSendContractStatusModal2(false);
			refetch();
		} catch (error) {
			toast.error("Unable to send loan contract");
			setIsLoading(false);
			setOpenAddContractModal(false);
		}
	};

	const handleMultipleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedContracts.map((row) => row.email).join(", ");

		setConfirmationMessage(
			`Are you sure you want to delete these contracts: ${emails}?`
		);
	};

	const handleSingleDelete = (row) => {
		setOpenConfirmationModal(true);
		setSelectedContracts([row]);
		setConfirmationMessage(
			`Are you sure you want to delete this contract: ${row?.email}?`
		);
	};

	const handleDeleteContracts = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: selectedContracts.map((row) => row.id),
			};

			const response = await deleteContracts(payload);
			if (response.success) {
				toast.success(response.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
			} else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
		} catch (error) {
			toast.error("Unable to delete contracts.");
			setIsLoading(false);
		}
	};
	const handleEdit = (row) => {
		setSelectedRow(row);
		setOpenAddContractModal(true);
	};

	return (
		<Box position="relative" minHeight="100vh">
			<Box display="flex" alignItems="center" gap={2} my={2}>
				<Box sx={{ flexGrow: 1 }}>
					<CustomSearchBar
						searchTerm={contractsSearchTerm}
						onSearchChange={handleContractsSearchChange}
						handleSearch={handleSearch}
					/>
				</Box>

				<Box sx={{ marginLeft: "auto" }}>
					{selectedContracts.length >= 1 && (
						<Box sx={{ marginLeft: "auto" }} display="flex" gap={2}>
							{selectedContracts.length === 1 && (
								<>
									<CardWithIcon
										icon={
											<Edit
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Edit"
										onClick={() => {
											setOpenAddContractModal(true);
										}}
									/>
									<CardWithIcon
										icon={
											<Send
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Send"
										onClick={() => {
											handleSendContractConfirmation();
										}}
									/>
									<CardWithIcon
										icon={
											<Update
												style={{
													color:
														theme.palette.mode == "light"
															? "rgba(73, 69, 79, 1)"
															: "white",
													marginRight: "5px",
													fontSize: "18px",
												}}
											/>
										}
										text="Update Status"
										onClick={() => {
											setOpenUpdateContractModal(true);
										}}
									/>
								</>
							)}
							<CardWithIcon
								icon={
									<Delete
										style={{
											color:
												theme.palette.mode == "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "18px",
										}}
									/>
								}
								text="Delete"
								onClick={() => {
									handleMultipleDelete();
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>

			<CustomTable
				isLoading={isContractsLoading || isRefetching}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="Update Contract" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							handleEdit(offer);
						},
					},
					{
						icon: (
							<Tooltip title="Delete Contract" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode === "light" ? "orange" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							handleSingleDelete(offer);
						},
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Description", key: "description", sortable: true },
					{
						title: "Contract Sent",
						key: "contractSent",
						sortable: true,
						isPill: true,
					},
					{
						title: "Contract Signed",
						key: "contractSigned",
						sortable: true,
						isPill: true,
					},
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredContractsData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => {
					setSelectedContracts(rows);
					setSelectedRow(rows[rows ? rows.length - 1 : 0]);
				}}
				selected={selectedContracts}
				totalItems={totalItems || 0}
				handleNextPage={handleFetchNewPage}
				page={page}
				handleSize={handleSize}
			/>

			{selectedRow && (
				<ContractsFloatingActionMenu
					selectedContracts={selectedContracts}
					data={selectedRow}
					refetch={refetch}
				/>
			)}
			<CreateContractModal
				open={openAddContractModal}
				onClose={() => setOpenAddContractModal(false)}
				contractData={selectedRow}
				type="contract"
			/>
			<ConfirmationModal
				open={openSendContractStatusModal}
				onClose={() => setOpenSendContractStatusModal(false)}
				handleConfirmAction={handleSendContract}
				message={confirmationMessage}
				isLoading={isLoading}
			/>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteContracts}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<SendConfirmationModal
				open={openSendContractStatusModal2}
				onClose={() => setOpenSendContractStatusModal2(false)}
				onConfirm={handleSendContract}
				message={confirmationMessage}
				isLoading={isLoading}
				isApproved={isApproved}
				setIsApproved={setIsApproved}
			/>
		</Box>
	);
};

export default ContractsTable;
