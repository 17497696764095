import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	useTheme,
	IconButton,
	Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { uploadAndLabelTransactions } from "../../services/retool-service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { WarningAmber } from "@mui/icons-material";

interface CsvTransactionsUploadModalProps {
	open: boolean;
	onClose: () => void;
	handleRefetch: () => void;
}

const CsvTransactionsUploadModal: React.FC<CsvTransactionsUploadModalProps> = ({
	open,
	onClose,
	handleRefetch,
}) => {
	const theme = useTheme();
	const [bankStatementData, setBankStatementData] = useState({
		description: "",
	});
	const [selectedFile, setSelectedFile] = useState<File | null>(null); // Only a single file
	const [isLoading, setIsLoading] = useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"text/csv": [".csv"],
		},
		multiple: false, // Allow only a single file
		onDrop: (acceptedFiles) => {
			setSelectedFile(acceptedFiles[0]); // Only keep the first file
		},
	});

	const handleFileRemove = () => {
		setSelectedFile(null); // Remove the selected file
	};

	const handleUploadSubmit = async () => {
		if (!selectedFile) return; // Prevent submission if no file is selected

		setIsLoading(true);

		const formData = new FormData();
		formData.append("documentRef", "bank_statement");
		formData.append("description", bankStatementData.description);
		formData.append("file", selectedFile); // Only append the single file

		try {
			const response = await uploadAndLabelTransactions(formData);

			if (response.data.success) {
				toast.success(response.data.message);
				handleRefetch();
			} else {
				toast.error(response.data.message);
			}

			handleUploadDialogClose();
		} catch (error) {
			toast.error("Failed to upload document");
		}

		setIsLoading(false);
	};

	const handleUploadDialogClose = () => {
		setSelectedFile(null); // Reset file state
		setBankStatementData({
			description: "",
		});
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					height: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					top: "2%",
					position: "relative",
					color: theme.palette.text.primary,
					overflowY: "auto",
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
					}}
				>
					Upload CSV Transactions
				</Typography>

				<Box
					{...getRootProps()}
					sx={{
						border: "2px dashed #cccccc",
						padding: "20px",
						textAlign: "center",
						marginBottom: "10px",
					}}
				>
					<input {...getInputProps()} />
					<Typography variant="body1">
						Drag 'n' drop a file here, or click to select a file
					</Typography>
				</Box>

				{/* Selected File */}
				{selectedFile && (
					<Box sx={{ marginTop: "10px" }}>
						<strong>Selected File:</strong>
						<ul>
							<li
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								{selectedFile.name}
								<IconButton
									onClick={handleFileRemove}
									size="small"
									sx={{ color: theme.palette.error.main }}
								>
									<DeleteIcon />
								</IconButton>
							</li>
						</ul>
					</Box>
				)}
				<CustomTextInput
					label="Description"
					value={bankStatementData.description}
					onChange={(e) =>
						setBankStatementData((prev) => ({
							...prev,
							description: e.target.value,
						}))
					}
					sx={{ marginTop: "20px" }}
					multiline
					rows={4}
				/>

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "10px",
						marginTop: "20px",
					}}
				>
					<CustomButton
						text="Cancel"
						variant="outlined"
						color="secondary"
						onClick={handleUploadDialogClose}
					/>

					<CustomButton
						text="Submit"
						variant="contained"
						onClick={handleUploadSubmit}
						disabled={isLoading || !selectedFile}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default CsvTransactionsUploadModal;
