import axios from 'axios';
import {axiosInstance} from "../services/auth-token";
import { format } from 'date-fns';

const CREDIT_REPORT_URL = `${process.env.REACT_APP_PUBLIC_API_URL}/CreditReport`;
const RETOOL_URL = `${process.env.REACT_APP_PUBLIC_API_URL}/Retool`;
const POST_INVESTMENT_URL = `${process.env.REACT_APP_PUBLIC_API_URL}/PostInvestmentMonitoring`;

const apiClient = axios.create({
    baseURL: RETOOL_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const apiClient2 = axios.create({
    baseURL: POST_INVESTMENT_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const apiClient3 = axios.create({
    baseURL: CREDIT_REPORT_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const exportCsvExcelExtract = async (data) => {
    return await apiClient.post('/export-csv-excel-extract', data);
};

export const getUsers = async (count, page) => {
    return await apiClient.get('/users', {
        params: {
            count,
            page
        }
    });
};

export const onboardSme = async (lendingApplicationId) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + `/onboard-sme/${lendingApplicationId}`);
};

export const getAllUsers = async () => {
    return await apiClient.get('/all-users');
};

export const addUser = async (userData) => {
    return await apiClient.post('/add-user', userData);
};

export const processAccountCreation = async (userData) => {
    return await apiClient.post('/process-account-creation', userData);
};

export const inviteUser = async (userId) => {
    return await apiClient.post(`/invite-user/${userId}`);
};

export const deleteUser = async (userId) => {
    return await apiClient.delete(`/delete-user/${userId}`);
};

export const deleteUsers = async (payload) => {
    try {
        const response = await apiClient.delete(`/delete-users`, { data: payload });
        return response.data;
    } catch (error) {
        console.error("Error deleting users", error);
        throw error;
    }
};

export const editUser = async (userId, userData) => {
    return await apiClient.post(`/edit-user/${userId}`, userData);
};

export const getLendingApplications = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/lending-applications', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const getPendingApplications = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/pending-applications', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const addLendingApplication = async (applicationData) => {
    return await apiClient.post('/add-lending-application', applicationData);
};

export const deleteLendingApplication = async (lendingApplicationId) => {
    return await apiClient.delete(`/delele-application/${lendingApplicationId}`);
};

export const updateDealStatus = async (dealStatusData) => {
    return await apiClient.post('/update-deal-status', dealStatusData);
};

export const updateDealsStatuses = async (dealStatusData) => {
    return await apiClient.post('/update-deals-statuses', dealStatusData);
};

export const updateLoanOfferStatus = async (offerStatusData) => {
    return await apiClient.post('/update-offer-status', offerStatusData);
};

export const updateLoanContractStatus = async (contractStatusData) => {
    return await apiClient.post('/update-contract-status', contractStatusData);
};

export const getLendingApplicationDocs = async (userId) => {
    return await apiClient.get(`/lending-application-docs/${userId}`);
};

export const getDealSummary = async (id) => {
    return await apiClient.get(`/deal-summary/${id}`);
};

export const updateDealSummary = async (dealSummaryData) => {
    return await apiClient.post('/deal-summary', dealSummaryData);
};

export const exportPdfWebhook = async (pdfWebhookData) => {
    return await apiClient.post('/pdf-export-webhook', pdfWebhookData);
};

export const getDealChecklist = async (lendingApplicationId) => {
    return await apiClient.get(`/deal-checklist/${lendingApplicationId}`);
};


export const getClientDealChecklists = async (userId) => {
    return await apiClient.get(`/client-deal-checklist/${userId}`);
};

export const requestBankAccountLink = async (bankAccountLinkData) => {
    return await apiClient.post('/request-bank-account-link', bankAccountLinkData);
};

export const exportDealSummary = async (lendingApplicationId) => {
    return await apiClient.get(`/export-deal-summary/${lendingApplicationId}`);
};

export const exportReports = async (reportData) => {
    return await apiClient.post('/export-reports', reportData);
};

export const uploadApplicationDocs = async (applicationDocsData) => {
    return await apiClient.post('/upload/application-docs', applicationDocsData, );
};

export const uploadClientDocs = async (applicationDocsData) => {
    return await axiosInstance.post(RETOOL_URL + '/upload/client-docs', applicationDocsData, );
};

export const uploadAndLabelTransactions = async (transactions) => {
    return await apiClient.post('upload-and-label-transactions', transactions, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const uploadLabelledTransactions = async (transactions) => {
    return await apiClient.post('upload-labelled-transactions', transactions, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};


export const uploadContract = async (applicationDocsData) => {
    return await apiClient.post('upload-loan-contract', applicationDocsData, );
};

export const uploadDealDocs = async (contractDocsData) => {
    return await apiClient2.post('/upload/application-docs', contractDocsData, );
};

export const uploadCsvFile = async (csvFile) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/review-transaction-csv', csvFile, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getBankStatementExtracts = async () => {
    return await apiClient.get('/bank-statement-extracts');
};

export const getUploadedBankStatements = async (userId) => {
    return await apiClient.get('/get-uploaded-bank-statements/'+userId);
};

export const getUploadedBankStatementsByConsumerId = async (consumerId) => {
    return await apiClient.get('/get-uploaded-bank-statements/'+consumerId);
};

export const getAllUploadedBankStatements = async () => {
    return await apiClient.get('/get-uploaded-bank-statements');
};

export const getTransactionHistories = async (userId) => {
    return await apiClient.get('/get-transaction-histories/'+userId);
};

export const generateLoanBookLedgerCode = async (ledgerCodeData) => {
    return await apiClient.post('/generate-ledger-code', ledgerCodeData);
};

export const exportOcrDocs = async (ocrDocsData) => {
    return await apiClient.post('/export-ocr-docs', ocrDocsData);
};

export const exportLendingApplicationDocs = async (userId) => {
    return await apiClient.post(`/export-lending-application-docs/${userId}`);
};

export const getUserProfile = async (userId) => {
    return await apiClient.get(`/user-profile/${userId}`);
};

export const getBusinessProfile = async (userId) => {
    return await apiClient.get(`/business-profile/${userId}`);
};

export const getCompanyDirectors = async (userId) => {
    return await apiClient.get(`/company-directors/${userId}`);
};

export const getDirectorshipStatus = async (idNumber) => {
    return await apiClient.get(`/directorship-status/${idNumber}`);
};

export const generateConsumerCreditReport = async (userId) => {
    return await apiClient.post(`/run-consumer-credit-check/${userId}`);
};

export const getConsumerCreditOverview = async (userId) => {
    return await apiClient.get(`/consumer-credit-overview/${userId}`);
};

export const getConsumerPropertySummary = async (userId) => {
    return await apiClient.get(`/consumer-property-summary/${userId}`);
};

export const getConsumerRepaymentHistory = async (userId) => {
    return await apiClient.get(`/consumer-repayment-history/${userId}`);
};

export const getConsumerAccountStatuses = async (userId) => {
    return await apiClient.get(`/consumer-account-status/${userId}`);
};

export const getConsumerDirectorshiplinks = async (userId) => {
    return await apiClient.get(`/consumer-directorship-link/${userId}`);
};

export const generateCommercialCreditReport = async (userId) => {
    return await apiClient.post(`/run-commercial-credit-check/${userId}`);
};

export const getCommercialCreditOverview = async (userId) => {
    return await apiClient.get(`/commercial-credit-overview/${userId}`);
};

export const getCommercialPrincipalInfo = async (userId) => {
    return await apiClient.get(`/commercial-principal-info/${userId}`);
};

export const getDirectorCurrentBusinessInterests = async (userId) => {
    return await apiClient.get(`/commercial-director-current-business-interests/${userId}`);
};

export const getDirectorPreviousBusinessInterests = async (userId) => {
    return await apiClient.get(`/commercial-director-previous-business-interests/${userId}`);
};

export const getDirectorPropertyInterests = async (userId) => {
    return await apiClient.get(`/commercial-director-property-interests/${userId}`);
};

export const getUserJourneyStats = async () => {
    return await apiClient.get('/user-journey-stats');
};

export const getSignUpStats = async () => {
    return await apiClient.get('/signup-stats');
};

export const getOnboardingStats = async () => {
    return await apiClient.get('/onboarding-stats');
};


export const getLendersSmeStatistics = async (startDate, endDate) => {
    try {
        const params = new URLSearchParams();
        if (startDate) params.append('startDate', format(startDate, 'yyyy-MM-dd'));
        if (endDate) params.append('endDate', format(endDate, 'yyyy-MM-dd'));

        const queryString = params.toString();
        const url = `${POST_INVESTMENT_URL}/get-lenders-sme-statistics${queryString ? `?${queryString}` : ''}`;

        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching lenders SME statistics:", error);
        throw error;
    }
};

export const getLendersPortfolioStatistics = async () => {
    try {      
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-lenders-portfolio-stats`);
        return response.data;
    } catch (error) {
        console.error("Error fetching lenders Portilio statistics:", error);
        throw error;
    }
};

export const getLendersSmeChartData = async () => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-lenders-sme-chart-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching lenders Sme chart data:", error);
        throw error;
    }

};

export const getTop5Smes= async () => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-top-5-smes`);
        return response.data;
    } catch (error) {
       
        throw error;
    }
};

export const getOnboardedDeals= async () => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-onboarded-deals`);
        return response.data;
    } catch (error) { 
        throw error;
    }
};

export const getSmeDeals= async () => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-sme-deals`);
        return response.data;
    } catch (error) { 
        throw error;
    }
};

export const getSmeDeal= async (lendersSMEId) => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-sme-deal?lendersSMEId=${lendersSMEId}`);
        return response.data;
    } catch (error) {   
        throw error;
    }
};

export const getMonthlyTransactionsSummary = async (lendersSMEId) => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-monthly-transactions-summary`, {
            params: {
                lendersSMEId
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getTransactionsSummary = async (lendersSMEId, filter) => {
    try {
        const response = await axiosInstance.get(`${POST_INVESTMENT_URL}/get-transactions-summary`, {
            params: {
                lendersSMEId,
                filter
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveNewAgent = async (agentData) => {
    return await apiClient.post('/save-new-agent', agentData);
};

export const updateAgent = async (agentId, agentData) => {
    return await apiClient.post(`/update-agent/${agentId}`, agentData);
};

export const deleteAgent = async (agentCode) => {
    return await apiClient.delete(`/delete-agent/${agentCode}`);
};

export const getLeads = async () => {
    return await apiClient.get('/leads');
};


export const getAgentLeads = async (agentCode) => {
    return await apiClient.get(`/get-agent-leads/${agentCode}`);
};

export const getAgents = async () => {
    return await apiClient.get('/agents');
};

export const getExcelCreditReportExtracts = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/excel-credit-report-extracts', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const runBankStatementsOcr = async (bankStatementOcrRequest) => {
    const formData = new FormData();
    for (const key in bankStatementOcrRequest) {
        formData.append(key, bankStatementOcrRequest[key]);
    }
    return await apiClient.post('/run-bank-statements-ocr', formData);
};

export const transactionsWebhook = async (collectionId) => {
    return await apiClient.post(`/ocr-transactions-hook/${collectionId}`);
};

export const createOcrExtractsIssueAuditTrail = async (ocrExtractsIssueAuditTrailRequest) => {
    return await apiClient.post('/create-ocr-extracts-issues', ocrExtractsIssueAuditTrailRequest);
};

export const knowYourClient = async (knowYourClientBusinessRequest) => {
    return await apiClient.post('/kyc', knowYourClientBusinessRequest);
};

export const knowYourBusiness = async (knowYourClientBusinessRequest) => {
    return await apiClient.post('/kyb', knowYourClientBusinessRequest);
};

export const generateManagementAccount = async (userId) => {
    return await apiClient.get(`/generate-management-account/${userId}`);
};

export const getManagementAccount = async (userId) => {
    return await apiClient.get(`/get-management-account/${userId}`);
};

export const fetchManagementAccount = async (userId) => {
    return await apiClient.get(`/fetch-management-account/${userId}`);
};

export const getTransactionUploadedStatuses = async () => {
    return await apiClient.get('/get-transaction-uploaded-statuses');
};

export const createUserAndProfile = async (createUserAndProfileRequest) => {
    return await apiClient.post('/create-user-and-profile', createUserAndProfileRequest);
};

export const saveFundingNeed = async (lendingApplicationRequest) => {
    return await apiClient.post('/save-funding-need', lendingApplicationRequest);
};

export const duplicateFundingNeed = async (lendingApplicationRequest) => {
    return await apiClient.post('/duplicate-funding-need', lendingApplicationRequest);
};

export const getFundingNeed = async (lendingApplicationId) => {
    return await apiClient.get(`/get-funding-need/${lendingApplicationId}`);
};

export const saveLoanDetails = async (lendingApplicationRequest) => {
    return await apiClient2.post('/save-loan-details', lendingApplicationRequest);
};

export const getLoanDetails = async (lendingApplicationId) => {
    return await apiClient2.get(`/get-loan-details/${lendingApplicationId}`);
};

export const saveApplicationDataRequest = async (applicationDataRequest) => {
    return await apiClient.post('/save-application-data-request', applicationDataRequest);
};

export const getApplicationDataRequest = async (id) => {
    return await apiClient.get(`/get-application-data-request/${id}`);
};

export const sendApplicationDataRequest = async (sendApplicationDataRequest) => {
    return await apiClient.post('/send-application-data-request', sendApplicationDataRequest);
};

export const submitFundingNeed = async (applicationId) => {
    return await apiClient.post(`/submit-funding-need/${applicationId}`);
};

export const submitLoanDetails = async (applicationId) => {
    return await apiClient2.post(`/submit-loan-details/${applicationId}`);
};

export const validateUploadedApplicationDocs = async (validateDocumentRequest) => {
    return await apiClient.post('/validate-uploaded-application-docs', validateDocumentRequest);
};

export const getOriginationApplicationsSummary = async () => {
    return await apiClient.get('/get-origination-applications-summary');
};

export const getOriginationApplications = async () => {
    return await apiClient.get('/get-origination-applications');
};

export const getLoanStatistics = async (filter) => {
    return await apiClient.get('/get-loan-statistics', {
        params: filter
    });
};

export const getCreditHistoryOverview = async (userId) => {
    return await apiClient.get(`/credit-history-overview/${userId}`);
};

export const getUnderwritingApplications = async () => {
    return await apiClient.get('/get-underwriting-applications');
};

export const getCreditAlgorithmResults = async (userId) => {
    return await apiClient.get(`/get-credit-algorithm-results/${userId}`);
};

export const getUnderwritingCreditScores = async (lendingApplicationId) => {
    return await apiClient.get(`/underwriting-credit-scores/${lendingApplicationId}`);
};

export const getTransHistories = async () => {
    return await apiClient.get('/transaction-histories');
};

export const createApplicationTask = async (request) => {
    return await apiClient.post('/create-application-task', request);
};

export const getAllApplicationTasks = async () => {
    return await apiClient.get('/get-application-tasks');
};

export const updateApplicationTask = async (id, request) => {
    return await apiClient.put(`/update-application-task/${id}`, request);
};

export const deleteApplicationTask = async (id) => {
    return await apiClient.delete(`/delete-application-task/${id}`);
};

export const createLendersTask = async (request) => {
    return await axiosInstance.post(`${RETOOL_URL}/create-lenders-task`, request);
};

export const getLendersTasks = async () => {
    return await axiosInstance.get(`${RETOOL_URL}/get-lenders-tasks`);
};

export const updateLendersTask = async (id, request) => {
    return await axiosInstance.put(`${RETOOL_URL}/update-lenders-task/${id}`, request);
};

export const deleteLendersTask = async (id) => {
    return await axiosInstance.delete(`${RETOOL_URL}/delete-lenders-task/${id}`);
};

export const uploadTransactionCsvExtract = async (uploadCsvTransactionRequest) => {
    const formData = new FormData();
    for (const key in uploadCsvTransactionRequest) {
        formData.append(key, uploadCsvTransactionRequest[key]);
    }
    return await apiClient.post('/upload-transaction-csv-extract', formData);
};

export const createOrUpdateDealChecklist = async (dealChecklistRequest) => {
    return await apiClient.post('/create-or-update-deal-checklists', dealChecklistRequest);
};

export const deleteDealChecklist = async (dealChecklistId) => {
    return await apiClient.delete(`/delete-deal-checklist/${dealChecklistId}`);
};

export const removeDocument = async (payload) => {
    return await apiClient.post(`/remove-document/`, payload);
};

export const removeBankStatements = async (payload) => {
    return await apiClient.post(`/delete-uploaded-statements/`, payload);
};

export const getAllProductTypes = async () => {
    return await apiClient.get('/get-product-types');
};

export const getDealChecklistsByProductType = async (productType) => {
    return await apiClient.get(`/get-deal-checklists/${productType}`);
};

export const getLoanOffers= async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/get-loan-offers', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const getLoanContracts = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/get-loan-contracts', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const getLendingApplicationsApprovedByOfficer = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/get-officer-approved-loans', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const getAcceptedLoanOffers = async () => {
    return await apiClient.get('/get-accepted-loan-offers');
};

export const createOrUpdateLoanOffer = async (loanOfferRequest) => {
    return await apiClient.post('/create-or-update-loan-offer', loanOfferRequest);
};

export const createOrUpdateLoanContract = async (loanOfferContractRequest) => {
    return await apiClient.post('/create-or-update-loan-contract', loanOfferContractRequest);
};

export const sendLoanOffer = async (loanOfferId, approveDeal) => {
    return await apiClient.post('/send-loan-offer', {id: loanOfferId, approveDeal});
};

export const sendLoanContract = async (loanContractId, approveDeal) => {
    return await apiClient.post('/send-loan-contract',{id: loanContractId, approveDeal});
};

export const getLoanOffersSummary = async () => {
    return await apiClient.get('/get-loan-offers-summary');
};

export const getLoanOfferQuotes = async (applicationId) => {
    return await apiClient.get(`/get-loan-offer-quotes/${applicationId}`);
};

export const getLoanBookRecords = async () => {
    return await apiClient.get('/loan-book-records');
};

export const updateUploadedQuoteStatus = async (updateUploadedQuoteRequest) => {
    return await apiClient.post('/update-uploaded-quote-status', updateUploadedQuoteRequest);
};

export const updateManyUploadedQuoteStatus = async (updateManyUploadedQuoteRequest) => {
    return await apiClient.post('/update-uploaded-quotes-status', updateManyUploadedQuoteRequest);
};

export const processUploadedQuote = async (quoteId) => {
    return await apiClient.post(`/process-uploaded-quote/${quoteId}`);
};

export const processUploadedQuotes = async (applicationId) => {
    return await apiClient.post(`/process-uploaded-quotes/${applicationId}`);
};

export const getUploadedQuotesSummary = async (applicationId) => {
    return await apiClient.get(`/get-uploaded-quotes-summary/${applicationId}`);
};

// Primary Category APIs
export const createPrimaryCategory = async (primaryCategory) => {
    return await apiClient.post(`/create-primary-category`, primaryCategory);
};

export const getPrimaryCategories = async () => {
    return await apiClient.get(`/get-primary-categories`);
};

export const getPrimaryCategoriesWithDetails = async () => {
    return await apiClient.get(`/get-primary-categories-details`);
};


export const updatePrimaryCategory = async (oldPrimaryCategory, newPrimaryCategory) => {
    return await apiClient.put(`/update-primary-category`, {
        oldPrimaryCategory,
        newPrimaryCategory
    });
};

export const deletePrimaryCategory = async (primaryCategory) => {
    return await apiClient.delete(`/delete-primary-category/${primaryCategory}`);
};

// Secondary Category APIs
export const createSecondaryCategory = async (primaryCategory, secondaryCategory) => {
    return await apiClient.post(`/create-secondary-category`, {
        primaryCategory,
        secondaryCategory
    });
};

export const getSecondaryCategoriesByPrimary = async (primaryCategory) => {
    return await apiClient.get(`/get-secondary-categories/${primaryCategory}`);
};

export const updateSecondaryCategory = async (oldSecondaryCategory, newSecondaryCategory) => {
    return await apiClient.put(`/update-secondary-category`, {
        oldSecondaryCategory,
        newSecondaryCategory
    });
};

export const deleteSecondaryCategory = async (secondaryCategoryId) => {
    return await apiClient.delete(`/delete-secondary-category/${secondaryCategoryId}`);
};

// Keyword APIs
export const createKeyword = async (payload) => {
   
    return await apiClient.post(`/create-keyword`, payload);
};

export const getKeywordsBySecondary = async (secondaryCategoryId) => {
    return await apiClient.get(`/get-keywords/${secondaryCategoryId}`);
};

export const updateKeyword = async (oldKeyword, newKeyword) => {
    return await apiClient.put(`/update-keyword`, {
        oldKeyword,
        newKeyword
    });
};

export const deleteKeyword = async (keywordId) => {
    return await apiClient.delete(`/delete-keyword/${keywordId}`);
};

export const getClients = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    try {
       
        const response = await apiClient.get(`/get-clients`, {
            params: {
                searchTerm,
                ...filters,
                page,
                size,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching clients", error);
        throw error;
    }
};

export const getClientsAndDealSummary = async () => {
    try {
        const response = await apiClient.get(`/get-client-and-deal-summary`);
        return response.data;
    } catch (error) {
        console.error("Error fetching clients and deal summary", error);
        throw error;
    }

};

export const getClientDeals = async (userId) => {
    try {
        const response = await apiClient.get(`/get-client-deals/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching deals for userId ${userId}`, error);
        throw error;
    }
};

export const softDeleteDeals = async (deleteLendingApplicationsRequest) => {
    try {
        const response = await apiClient.delete(`/delete-deals`, { data: deleteLendingApplicationsRequest });
        return response.data;
    } catch (error) {
        console.error("Error deleting deals", error);
        throw error;
    }
};


export const deleteOffers = async (deleteLendingApplicationsRequest) => {
    try {
        const response = await apiClient.delete(`/delete-offers`, { data: deleteLendingApplicationsRequest });
        return response.data;
    } catch (error) {
        console.error("Error deleting offers", error);
        throw error;
    }
};

export const deleteContracts = async (deleteLendingApplicationsRequest) => {
    try {
        const response = await apiClient.delete(`/delete-contracts`, { data: deleteLendingApplicationsRequest });
        return response.data;
    } catch (error) {
        console.error("Error deleting contracts", error);
        throw error;
    }
};

export const deleteLoanOffer = async (id) => {
    try {
        const response = await apiClient.delete(`/delete-loan-offer/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting loan offer", error);
        throw error;
    }
};

export const deleteLoanContract = async (id) => {
    try {
        const response = await apiClient.delete(`/delete-loan-contract/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting loan contract.", error);
        throw error;
    }
};

export const getClientAndDealSummary = async () => {
    try {
        const response = await apiClient.get(`/get-client-and-deal-summary`);
        return response.data;
    } catch (error) {
        console.error("Error fetching client and deal summary", error);
        throw error;
    }
};

export const updateUserAndBusinessProfile = async (knowYourClientBusinessRequest) => {
    try {
        const response = await apiClient.post(`/update-profiles`, knowYourClientBusinessRequest);
        return response.data;
    } catch (error) {
        console.error("Error updating profiles", error);
        throw error;
    }
};

export const exportTransactionHistories = async (exportTransactionHistoryRequest) => {
    try {
        const response = await apiClient.post(`/export-transaction-histories`, exportTransactionHistoryRequest);
        return response.data;
    } catch (error) {
        console.error("Error exporting transaction histories", error);
        throw error;
    }
};

export const exportMultipleTransactionHistories = async (exportTransactionHistoryRequest) => {
    try {
        const response = await apiClient.post(`/export-multiple-transaction-histories`, exportTransactionHistoryRequest);
        return response.data;
    } catch (error) {
        console.error("Error exporting multiple transaction histories", error);
        throw error;
    }
};

export const runKycUsingEmail = async (knowYourClientBusinessRequest) => {
    try {
        const response = await apiClient.post(`/run-kyc`, knowYourClientBusinessRequest);
        return response.data;
    } catch (error) {
        console.error("Error running KYC using email", error);
        throw error;
    }
};

export const runKybUsingEmail = async (knowYourClientBusinessRequest) => {
    try {
        const response = await apiClient.post(`/run-kyb`, knowYourClientBusinessRequest);
        return response.data;
    } catch (error) {
        console.error("Error running KYB using email", error);
        throw error;
    }
};


export const createLendingReminder = async (lendingReminderRequest) => {
    return await apiClient.post('/create-lending-reminder', lendingReminderRequest);
};


export const updateLendingReminder = async (id, updatedReminder) => {
    return await apiClient.put(`/update-lending-reminder/${id}`, updatedReminder);
};

export const createOrUpdateLendingReminder = async (lendingReminderRequest) => {
    return lendingReminderRequest.id == 0 ? 
    await apiClient.post('/create-lending-reminder', lendingReminderRequest) : 
    await apiClient.put(`/update-lending-reminder/${lendingReminderRequest.id}`, lendingReminderRequest);

};

export const deleteLendingReminder = async (id) => {
    return await apiClient.delete(`/delete-lending-reminder/${id}`);
};

export const deleteMultipleLendingReminders = async (ids) => {
    return await apiClient.post('/delete-multiple-lending-reminders', ids);
};

export const getAllLendingReminders = async () => {
    return await apiClient.get('/get-all-lending-reminders');
};

export const getLendingReminderById = async (id) => {
    return await apiClient.get(`/get-lending-reminder/${id}`);
};

export const getConsumerCreditReport = async (userId) => {
    return await apiClient3.get(`/consumer-credit-report/${userId}`);
};


export const getCommercialCreditReport = async (userId) => {
    return await apiClient3.get(`/commercial-credit-report/${userId}`);
};


export const getBackgroundJobs = async () => {
    return await apiClient.get(`/get-background-jobs`);
};

export const getClientBackgroundJobs = async (userId) => {
    return await apiClient.get(`/get-background-jobs/${userId}`);
};

export const getUserAssessement = async (userId) => {
    return await apiClient.get(`/get-user-assessment/${userId}`);
};

export const getFundingLadder = async (userId) => {
    return await apiClient.get(`/get-funding-ladder/${userId}`);
};

export const getDealOverview = async () => {
    return await apiClient.get(`/get-deal-overview`);
};

export const getBankAccounts = async ({
    searchTerm = "",
    filters = {},
    page = 1,
    size = 10,
}) => {
    return await apiClient.get('/get-bank-accounts', {
        params: {
            searchTerm,
            ...filters,
            page,
            size,
        },
    });
};

export const deleteBankAccount = async (id) => {
    return await apiClient.delete(`/delele-application/${id}`);
};

export const updateBankAccount = async (bankAccount) => {
    return await apiClient.put(`/update-bank-account/${bankAccount.id}`, bankAccount);
};

export const createBankAccount = async (bankAccount) => {
    return await apiClient.post('/create-bank-account', bankAccount);
};

export const getClientBankAccounts = async (userId) => {
    return await apiClient.get(`/get-client-bank-accounts/${userId}`);
};

export const getClientLabelledTransactions = async (userId, bankAccountId) => {
    return await apiClient.get(`/get-client-transactions/${userId}?bankAccountId=${bankAccountId}`);
};
