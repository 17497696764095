import React from "react";
import {
	Box,
	Typography,
	Grid,
	Modal,
	useTheme,
	IconButton,
} from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import BackgroundJobTable from "../Tables/BackgroudJobTable";
import { Close } from "@mui/icons-material";
import BankAccountTable from "../Tables/BankAccountTable";

const BankAccountModal = ({ open, onClose, userId }) => {
	const theme = useTheme();
	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					maxHeight: "100%",
					backgroundColor: "white",
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					overflowY: "auto",

					position: "relative",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "24px", fontWeight: "500" }}
					>
						Bank Account
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<BankAccountTable userId={userId} />

				<Box
					sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}
				>
					<CustomButton
						text="Close"
						onClick={onClose}
						variant="outlined"
						color="secondary"
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default BankAccountModal;
