import React, { useState, useEffect } from "react";
import {
	Box,
	useTheme,
	Modal,
	Typography,
	CircularProgress,
	IconButton,
	Tooltip,
} from "@mui/material";
import { toast } from "react-hot-toast";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import { AddCircle, Close, Delete, Visibility } from "@mui/icons-material";
import {
	deleteBankAccount,
	updateBankAccount,
} from "../../../common/services/retool-service";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { useFetchClientBankAccounts } from "../../../common/helpers/hooks/useBankAccountService";
import CustomCardHeaderWithIcon from "../Cards/CustomCardHeaderWithIcon";
import CreateBankAccount from "../Modals/CreateBankAccountModal";
import EditBankAccount from "../Modals/EditBankAccountModal";
import CardWithIcon from "../Elements/CardWithIcon";

const BankAccountTable = ({ userId }) => {
	const theme = useTheme();

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openCreateBankAccountModal, setOpenCreateBankAccountModal] =
		useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isDeleting, setIsDeleting] = useState(false);
	const [editingData, setEditingData] = useState({
		accountName: "",
		accountNumber: "",
		bankName: "",
		userId,
		id: 0,
	});

	const {
		data: bankAccounts = [],
		isLoading,
		refetch,
	} = useFetchClientBankAccounts(userId);

	useEffect(() => {
		if (bankAccounts?.data?.data) {
			const data = bankAccounts?.data?.data;

			const searchResults = data.filter((row) =>
				["accountName", "accountNumber", "bankName"].some((key) =>
					row[key]?.toLowerCase().includes(searchTerm.toLowerCase())
				)
			);
			setFilteredData(searchResults);
		}
	}, [searchTerm, bankAccounts]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleEdit = (row) => {
		setEditingData(row);
		setSelectedRow(row);
		setOpenEditModal(true);
	};

	const handleDelete = (row) => {
		setConfirmationMessage(
			`Are you sure you want to delete the account: ${row.accountName}?`
		);
		setSelectedRow(row);
		setOpenConfirmationModal(true);
	};

	const handleConfirmDelete = async () => {
		setIsDeleting(true);
		toast.loading("Deleting bank account...");

		try {
			await deleteBankAccount(selectedRow?.id);
			toast.success("Bank account deleted successfully.");
			refetch();
		} catch (error) {
			toast.error("Failed to delete bank account.");
		} finally {
			setIsDeleting(false);
			setOpenConfirmationModal(false);
		}
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={2}
			position="relative"
			minHeight="100vh"
		>
			<Box
				width="100%"
				my={2}
				gap={2}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<CustomSearchBar
						searchTerm={searchTerm}
						onSearchChange={handleSearchChange}
					/>
				</Box>
				<Box sx={{ marginLeft: "auto" }}>
					<CardWithIcon
						icon={
							<AddCircle
								style={{
									color:
										theme.palette.mode == "light"
											? "rgba(73, 69, 79, 1)"
											: "white",
									marginRight: "5px",
									fontSize: "18px",
								}}
							/>
						}
						text="Create Bank Account"
						onClick={() => {
							setOpenCreateBankAccountModal(true);
						}}
					/>
				</Box>
			</Box>

			<CustomTable
				isLoading={isLoading}
				icons={(row) => [
					{
						icon: (
							<Tooltip title="View Details" arrow>
								<Visibility
									sx={{
										fill:
											theme.palette.mode == "light"
												? "rgba(73, 69, 79, 1)"
												: "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							handleEdit(row);
						},
					},
					{
						icon: (
							<Tooltip title="Delete Bank Account" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode == "light" ? "red" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => handleDelete(row),
					},
				]}
				columns={[
					{ title: "Account Name", key: "accountName", sortable: true },
					{ title: "Account Number", key: "accountNumber", sortable: true },
					{ title: "Bank Name", key: "bankName", sortable: true, isPill: true },
				]}
				data={filteredData ? filteredData : []}
				selectable
				onRowSelect={(rows) => {
					setSelectedRows(rows);
					setSelectedRow(rows[rows ? rows.length - 1 : 0]);
				}}
				selected={selectedRows}
				actionColumn={{
					title: "Actions",
				}}
			/>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleConfirmDelete}
				message={confirmationMessage}
				isLoading={isDeleting}
			/>

			<CreateBankAccount
				open={openCreateBankAccountModal}
				onClose={() => setOpenCreateBankAccountModal(false)}
				userId={userId}
			/>

			<EditBankAccount
				open={openEditModal}
				onClose={() => setOpenEditModal(false)}
				initialData={selectedRow}
				userId={userId}
			/>
		</Box>
	);
};

export default BankAccountTable;
