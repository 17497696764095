import React, { useEffect, useState } from "react";
import BankAccountModal from "./BankAccountModal";
import BankAccountForm from "../Forms/BankAccountForm";
import { updateBankAccount } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";

const EditBankAccount = ({ open, onClose, userId, initialData }) => {
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState({
		accountName: "",
		accountNumber: "",
		bankName: "",
		userId,
		id: 0,
	});

	const handleSaveEdit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Updating bank account...");

		try {
			const response = await updateBankAccount(formData);
			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				queryClient.invalidateQueries("clientBankAccounts");
			} else toast.error(response.data.message, { id: toastId });
		} catch (error) {
			toast.error("Failed to update bank account.", { id: toastId });
		} finally {
			onClose();
			setFormData({
				accountName: "",
				accountNumber: "",
				bankName: "",
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (initialData) {
			setFormData((prev) => ({
				...prev,
				...initialData,
			}));
		}
	}, [initialData]);

	return (
		<BankAccountForm
			open={open}
			onClose={onClose}
			title="Edit Bank Account"
			formData={formData}
			setFormData={setFormData}
			onSave={handleSaveEdit}
			isLoading={isLoading}
		/>
	);
};

export default EditBankAccount;
