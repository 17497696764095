import React, { useEffect } from "react";
import { Input, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	setEndDate,
	setStartDate,
} from "../../../common/features/filter/dateSlice";
import { RootState } from "../../../app/store";
import moment from "moment";

const DateFilter: React.FC = () => {
	const dispatch = useDispatch();
	const { startDate, endDate } = useSelector((state: RootState) => state.date);

	useEffect(() => {
		if (!startDate || !endDate) {
			const defaultStartDate = moment()
				.subtract(12, "months")
				.format("YYYY-MM-DD");
			const defaultEndDate = moment().format("YYYY-MM-DD");
			dispatch(setStartDate(defaultStartDate));
			dispatch(setEndDate(defaultEndDate));
		}
	}, [startDate, endDate, dispatch]);

	const handleStartDateChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newStartDate = event.target.value;
		dispatch(setStartDate(newStartDate));
		if (endDate && endDate < newStartDate) {
			dispatch(setEndDate(newStartDate));
		}
	};

	const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setEndDate(event.target.value));
	};

	const isEndDateDisabled = !startDate;

	return (
		<Box display="flex" gap={2} alignItems="center">
			<Box display="flex" alignItems="center" gap={1}>
				<Typography variant="body2" color="textSecondary">
					From
				</Typography>
				<Input
					type="date"
					onChange={handleStartDateChange}
					value={startDate || ""}
					disableUnderline
					sx={{
						backgroundColor: "white",
						padding: "2px 8px",
						fontSize: "12px",
						width: "120px",
					}}
				/>
			</Box>
			<Box display="flex" alignItems="center" gap={1}>
				<Typography variant="body2" color="textSecondary">
					To
				</Typography>
				<Input
					type="date"
					onChange={handleEndDateChange}
					value={endDate || ""}
					disabled={isEndDateDisabled}
					disableUnderline
					sx={{
						backgroundColor: "white",
						padding: "2px 8px",
						fontSize: "12px",
						width: "120px",
					}}
				/>
			</Box>
		</Box>
	);
};

export default DateFilter;
